<!-- Created by Julio Pramaitama -->
<template>
  <div>
    <a
      href="#"
      @click="scrollToTop"
      v-show="showTopButton"
      id="back-to-top"
      class="fixed z-10 text-lg leading-9 text-center text-white bg-blue-500 rounded-full bottom-5 right-5 size-9"
    >
      <i class="mdi mdi-arrow-up"></i>
    </a>
    <!-- Back to top -->

    <!-- Switcher -->
    <div class="fixed top-1/4 -right-1 z-3">
      <span class="relative inline-block rotate-90">
        <input
          type="checkbox"
          class="absolute opacity-0 checkbox"
          id="chk"
          @change="changeMode($event)"
        />
        <label
          class="flex items-center justify-between h-8 p-1 rounded-full shadow cursor-pointer label bg-slate-900 dark:bg-white dark:shadow-gray-800 w-14"
          for="chk"
        >
          <i data-feather="moon" class="w-[18px] h-[18px] text-yellow-500"></i>
          <i data-feather="sun" class="w-[18px] h-[18px] text-yellow-500"></i>
          <span
            class="ball bg-white dark:bg-slate-900 rounded-full absolute top-[2px] left-[2px] size-7"
          ></span>
        </label>
      </span>
    </div>
    <!-- Switcher -->

    <!-- LTR & RTL Mode Code -->
    <div class="fixed top-[40%] -right-3 z-50" dir="ltr">
      <a href="javascript:void(0)" id="switchRtl" @click="decrement">
        <span
          class="relative inline-block px-3 py-1 font-medium -rotate-90 bg-white shadow-md rounded-t-md dark:bg-slate-900 dark:shadow dark:shadow-gray-800 rtl:block ltr:hidden"
          @click="changeThem($event)"
          >LTR</span
        >
        <span
          class="relative inline-block px-3 py-1 font-medium -rotate-90 bg-white shadow-md rounded-t-md dark:bg-slate-900 dark:shadow dark:shadow-gray-800 ltr:block rtl:hidden"
          @click="changeThem($event)"
          >RTL</span
        >
      </a>
    </div>
  </div>
</template>

<script>
import feather from "feather-icons";
export default {
  data() {
    return {
      htmlTag: document.getElementsByTagName("html")[0],
      showTopButton: false,
    };
  },

  mounted() {
    feather.replace();
  },

  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },

  methods: {
    handleScroll() {
      if (
        document.body.scrollTop >= 50 ||
        document.documentElement.scrollTop >= 50
      ) {
        this.showTopButton = true;
      } else {
        this.showTopButton = false;
      }
    },
    changeThem(event) {
      if (event.target.innerText === "LTR") {
        this.htmlTag.dir = "ltr";
      } else {
        this.htmlTag.dir = "rtl";
      }
    },

    changeMode() {
      if (this.htmlTag.className.includes("dark")) {
        this.htmlTag.className = "light";
      } else {
        this.htmlTag.className = "dark";
      }
    },

    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
};
</script>
./switcher.vue

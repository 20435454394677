<!-- Created by Julio Pramaitama -->
<template>
  <!-- Footer Start -->
  <footer
    class="relative text-gray-200 footer bg-dark-footer dark:text-gray-200"
  >
    <div class="py-[30px] px-0 border-t border-slate-800">
      <div class="container text-center">
        <div
          class="grid items-center grid-cols-1 lg:grid-cols-12 md:grid-cols-3"
        >
          <div class="text-center lg:col-span-3 md:text-start">
            <a href="/" class="text-[22px] focus:outline-none">
              <img
                src="../assets/images/logo-light.svg"
                class="mx-auto md:me-auto md:ms-0"
                alt=""
              />
            </a>
          </div>

          <div class="mt-6 text-center lg:col-span-5 md:mt-0">
            <router-link to="/releases">
              <p class="mb-0">
                © 2023-{{ date }}
                <i class="text-red-600 mdi mdi-heart animate-ping"></i> by
                <a href="/" target="_blank" class="text-reset">julio</a>.
              </p>
            </router-link>
          </div>

          <ul
            class="mt-6 text-center list-none lg:col-span-4 md:text-end md:mt-0"
          >
            <li class="inline">
              <a
                href="https://github.com/JulioPr0"
                target="_blank"
                class="border border-gray-800 rounded-md btn btn-icon btn-sm hover:border-blue-500 dark:hover:border-blue-500 hover:bg-blue-500 dark:hover:bg-blue-500"
              >
                <i class="align-middle uil uil-github-alt" title="GitHub"></i>
              </a>
            </li>
            <li class="inline">
              <a
                href="https://www.behance.net/juliopramaitama"
                target="_blank"
                class="border border-gray-800 rounded-md btn btn-icon btn-sm hover:border-blue-500 dark:hover:border-blue-500 hover:bg-blue-500 dark:hover:bg-blue-500"
              >
                <i class="uil uil-behance" title="Behance"></i>
              </a>
            </li>
            <li class="inline">
              <a
                href="https://www.linkedin.com/in/juliopramaitama"
                target="_blank"
                class="border border-gray-800 rounded-md btn btn-icon btn-sm hover:border-blue-500 dark:hover:border-blue-500 hover:bg-blue-500 dark:hover:bg-blue-500"
              >
                <i class="uil uil-linkedin" title="Linkedin"></i>
              </a>
            </li>
            <li class="inline">
              <router-link
                to="/weixin"
                class="border border-gray-800 rounded-md btn btn-icon btn-sm hover:border-blue-500 dark:hover:border-blue-500 hover:bg-blue-500 dark:hover:bg-blue-500"
              >
                <svg
                  role="img"
                  width="20"
                  class="fill-current"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <title>WeChat</title>
                  <path
                    d="M8.691 2.188C3.891 2.188 0 5.476 0 9.53c0 2.212 1.17 4.203 3.002 5.55a.59.59 0 0 1 .213.665l-.39 1.48c-.019.07-.048.141-.048.213 0 .163.13.295.29.295a.326.326 0 0 0 .167-.054l1.903-1.114a.864.864 0 0 1 .717-.098 10.16 10.16 0 0 0 2.837.403c.276 0 .543-.027.811-.05-.857-2.578.157-4.972 1.932-6.446 1.703-1.415 3.882-1.98 5.853-1.838-.576-3.583-4.196-6.348-8.596-6.348zM5.785 5.991c.642 0 1.162.529 1.162 1.18a1.17 1.17 0 0 1-1.162 1.178A1.17 1.17 0 0 1 4.623 7.17c0-.651.52-1.18 1.162-1.18zm5.813 0c.642 0 1.162.529 1.162 1.18a1.17 1.17 0 0 1-1.162 1.178 1.17 1.17 0 0 1-1.162-1.178c0-.651.52-1.18 1.162-1.18zm5.34 2.867c-1.797-.052-3.746.512-5.28 1.786-1.72 1.428-2.687 3.72-1.78 6.22.942 2.453 3.666 4.229 6.884 4.229.826 0 1.622-.12 2.361-.336a.722.722 0 0 1 .598.082l1.584.926a.272.272 0 0 0 .14.047c.134 0 .24-.111.24-.247 0-.06-.023-.12-.038-.177l-.327-1.233a.582.582 0 0 1-.023-.156.49.49 0 0 1 .201-.398C23.024 18.48 24 16.82 24 14.98c0-3.21-2.931-5.837-6.656-6.088V8.89c-.135-.01-.27-.027-.407-.03zm-2.53 3.274c.535 0 .969.44.969.982a.976.976 0 0 1-.969.983.976.976 0 0 1-.969-.983c0-.542.434-.982.97-.982zm4.844 0c.535 0 .969.44.969.982a.976.976 0 0 1-.969.983.976.976 0 0 1-.969-.983c0-.542.434-.982.969-.982z"
                  />
                </svg>
              </router-link>
            </li>
            <li class="inline">
              <a
                href="https://line.me/ti/p/-AhQysSrHl"
                target="_blank"
                class="border border-gray-800 rounded-md btn btn-icon btn-sm hover:border-blue-500 dark:hover:border-blue-500 hover:bg-blue-500 dark:hover:bg-blue-500"
              >
                <i class="align-middle uil uil-line" title="Line"></i>
              </a>
            </li>
            <li class="inline">
              <a
                href="https://www.instagram.com/julpramaitama/"
                target="_blank"
                class="border border-gray-800 rounded-md btn btn-icon btn-sm hover:border-blue-500 dark:hover:border-blue-500 hover:bg-blue-500 dark:hover:bg-blue-500"
              >
                <i class="align-middle uil uil-instagram" title="Instagram"></i>
              </a>
            </li>
            <li class="inline">
              <a
                href="mailto:work@websitejulio.com?subject=From Websitejulio&body=Halo%20Saya%20dari%20perusahaan"
                class="border border-gray-800 rounded-md btn btn-icon btn-sm hover:border-blue-500 dark:hover:border-blue-500 hover:bg-blue-500 dark:hover:bg-blue-500"
              >
                <i class="align-middle uil uil-envelope" title="Email"></i>
              </a>
            </li>
          </ul>
          <!--end icon-->
        </div>
        <!--end grid-->
      </div>
      <!--end container-->
    </div>
  </footer>
  <!--end footer-->
  <!-- Footer End -->
</template>

<script>
export default {
  name: "footerComp",
  data() {
    return {
      date: new Date().getFullYear(),
    };
  },
};
</script>

<style lang="scss" scoped></style>
